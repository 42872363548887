<mat-spinner *ngIf="!brand"></mat-spinner>
<div *ngIf="brand" class="brand-form-wrapper">
  <div class="brand-form">
    <form [formGroup]="brandForm">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input id="brand_form_name_input" matNativeControl formControlName="name">
      </mat-form-field>
      <mat-form-field>
        <mat-label>BEMInnovate.com/?</mat-label>
        <input id="brand_form_uri_input" matNativeControl formControlName="uri">
      </mat-form-field>
      <ng-container [formGroup]="brandForm.get('url')">
        <mat-form-field>
          <mat-label>Website name</mat-label>
          <input id="brand_form_url_name_input" matNativeControl formControlName="name">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Website URL</mat-label>
          <input id="brand_form_url_input" matNativeControl formControlName="url">
        </mat-form-field>
      </ng-container>

      <ng-container [formGroup]="brandForm.get('social_links')">
        <mat-form-field>
          <mat-label>Facebook</mat-label>
          <input matNativeControl formControlName="facebook">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Instagram</mat-label>
          <input matNativeControl formControlName="instagram">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Twitter</mat-label>
          <input matNativeControl formControlName="twitter">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Linkedin</mat-label>
          <input matNativeControl formControlName="linkedin">
        </mat-form-field>
        <mat-form-field>
          <mat-label>YouTube</mat-label>
          <input matNativeControl formControlName="youtube">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Discord</mat-label>
          <input matNativeControl formControlName="discord">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Google Play Store</mat-label>
          <input matNativeControl formControlName="googlePlay">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Apple App Store</mat-label>
          <input matNativeControl formControlName="appleStore">
        </mat-form-field>
      </ng-container>

    </form>
    <input #imageInput id="brand_form_image_input" hidden="true" type="file" (change)="fileChangeEvent($event, 'image')"
           accept=".jpg,.png"/>
    <div class="image-holder" *ngIf="imageChangedEvent !== ''">
      <image-cropper
        [style.maxHeight.px]="200"
        [imageChangedEvent]="imageChangedEvent"
        format="png"
        [imageQuality]="100"
        [aspectRatio]="180/80"
        [cropperMinWidth]="180"
        [cropperMinHeight]="80"
        [maintainAspectRatio]="true"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded('image')"
        (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"
      ></image-cropper>
    </div>

    <button mat-flat-button color="primary" id="brand_form_choose_button" (click)="imageInput.click()">Choose image
    </button>

    <button mat-flat-button color="primary" id="brand_form_upload_button" *ngIf="imageChangedEvent !== ''"
            (click)="uploadImage('image')">Upload image
    </button>

    <input #imageCoverInput id="brand_form_cover_image_input" hidden="true" type="file"
           (change)="fileChangeEvent($event, 'cover')"
           accept=".jpg,.png"/>
    <div class="image-holder" *ngIf="coverImageChangedEvent !== ''">
      <image-cropper
        [style.maxHeight.px]="200"
        [imageChangedEvent]="coverImageChangedEvent"
        format="png"
        [imageQuality]="100"
        [aspectRatio]="1920/500"
        [maintainAspectRatio]="true"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded('cover')"
        (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"
      ></image-cropper>
    </div>
    <button mat-flat-button color="primary" id="brand_form_choose_button_two" (click)="imageCoverInput.click()">Choose
      cover
      image
    </button>

    <button mat-flat-button color="primary" id="brand_form_upload_button_two" *ngIf="coverImageChangedEvent !== ''"
            (click)="uploadImage('cover')">Upload cover image
    </button>
    <div class="tag-input--wrapper">
      <p>Add tags</p>
      <tag-input class="px-border input-border-gray-color w100p" [(ngModel)]="tags"
                 [identifyBy]="'id'"
                 [maxItems]="100"
                 [displayBy]="'name'"
                 secondaryPlaceholder=""
                 [separatorKeyCodes]="[188]"
                 (onRemove)="onTagsChange()"
                 [animationDuration]="{enter: '0ms', leave: '0ms'}"
                 (onAdd)="onTagsChange()"
                 [theme]="'bem-theme'">
        <tag-input-dropdown [identifyBy]="'id'" [displayBy]="'tag'"></tag-input-dropdown>
      </tag-input>
    </div>
    <!--    <angular2-multiselect [data]="countries" [(ngModel)]="selectedCountries"-->
    <!--                          [settings]="countriesSettings"></angular2-multiselect>-->
    <!--    <angular2-multiselect [data]="industries" [(ngModel)]="selectedIndustries"-->
    <!--                          [settings]="industriesSettings"></angular2-multiselect>-->
    <button mat-flat-button color="primary" id="brand_form_submit_button" (click)="submit()">Submit</button>
  </div>
  <div *ngIf="brand && brand?.uri" class="products">
    <div class="product" *ngFor="let product of products">
      <img [src]="'/assets/img/innovation/' + product.image">
      <div>
        <h2>{{product.name}}</h2>
        <a *ngIf="brand.TYPE === 'OPEN' && product.url?.url" class="bem-blue" target="_blank" [href]="product.url.url">Website</a>
      </div>
      <div class="action-buttons">
        <span class="blue-hover pointer no-outline noselect" id="brand_form_product_{{product.id}}_edit_button"
              (click)="editProduct(product)">Edit</span> /
        <span class="blue-hover pointer no-outline noselect" id="brand_form_product_{{product.id}}_delete_button"
              (click)="deleteProduct(product.id)">Delete</span></div>
    </div>
    <button mat-flat-button color="primary" id="brand_form_product_add_button" (click)="onAddProduct()">Add product
    </button>
  </div>
</div>
