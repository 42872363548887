import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GoalFormComponent } from './forms/goal-form/goal-form.component';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import { ProductFormComponent } from './forms/product-form/product-form.component';
import { BrandFormComponent } from './forms/brand-form/brand-form.component';
import { LoginComponent } from './login/login.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatToolbarModule} from '@angular/material/toolbar';
import {ImageCropperModule} from 'ngx-image-cropper';
import { DashboardComponent } from './dashboard/dashboard.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {AngularMultiSelectModule} from 'angular2-multiselect-dropdown';
import {MatDialogModule} from '@angular/material/dialog';
import { SettingsFormComponent } from './forms/settings-form/settings-form.component';
import {MatSelectModule} from "@angular/material/select";
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";
import { SanitizehtmlPipe } from './pipes/sanitizehtml.pipe';
import { TagInputModule } from 'ngx-chips';

@NgModule({
  declarations: [
    AppComponent,
    GoalFormComponent,
    ProductFormComponent,
    BrandFormComponent,
    LoginComponent,
    DashboardComponent,
    SettingsFormComponent,
    SanitizehtmlPipe,
  ],
  imports: [
    CKEditorModule,
    AngularMultiSelectModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    HttpClientModule,
    MatSnackBarModule,
    MatToolbarModule,
    ImageCropperModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatSelectModule,
    TagInputModule
  ],
  providers: [],
  entryComponents: [
    ProductFormComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
