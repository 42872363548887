import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {RequestsService} from './requests.service';

@Injectable({
  providedIn: 'root'
})
export class LoggeduserService {

  token: string = null;

  constructor(private httpClient: HttpClient) { }

  getToken() {
    if (!this.token) {
      let cookieToken;
      if (document.cookie !== '' && document.cookie.indexOf('token') !== -1) {
        cookieToken = document.cookie.split('; ').find(row => row.startsWith('token')).split('=')[1];
      } else {
        cookieToken = null;
      }
      return cookieToken;
    } else {
      return this.token;
    }
  }

  setToken(token) {
    this.token = token;
  }

  loginUser(username: string, password: string) {
    return this.httpClient.post('/iapi/user/login', {username, password}).toPromise();
  }

  checkUser() {
    const header = {headers: new HttpHeaders({Authorization: this.getToken()})};
    return this.httpClient.get('/iapi/user/login', header).toPromise();
  }
}
