import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {LoggeduserService} from '../services/loggeduser.service';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm = this.fb.group({
    username: ['', [Validators.email, Validators.required]],
    password: ['', Validators.required],
  });

  constructor(private fb: FormBuilder, private http: HttpClient, private loggedUser: LoggeduserService, private router: Router,
              private snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  submit(): void {
    if (this.loginForm.valid) {
      this.loggedUser.loginUser(this.loginForm.get('username').value, this.loginForm.get('password').value)
        .then(res => {
          this.loggedUser.checkUser().then( user => {
            if ((user as any).role_flags === 2048) {
              this.snackBar.open('Successfully logged in.', 'Close', {duration: 2000});
              this.loggedUser.setToken((res as any).token);
              this.router.navigate(['/']);
            } else {
              this.snackBar.open('Please login as admin.', 'Close', {duration: 2000});
            }
          });
        })
        .catch(err => window.alert(err.error.message));
    } else {
      this.snackBar.open('Please enter a valid email address or password.', 'Close', {duration: 2000});
    }
  }

}
