import {Component, OnDestroy, OnInit} from '@angular/core';
import {FetchDataService} from '../services/fetch-data.service';
import {RequestsService} from '../services/requests.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();

  goals;
  brands;
  products;

  constructor(private fetch: FetchDataService, private requests: RequestsService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.fetch.goals$.pipe(takeUntil(this.destroy$)).subscribe({
      next: value => {
        this.goals = value;
      }
    });
    this.fetch.getGoals();
    this.fetch.brands$.pipe(takeUntil(this.destroy$)).subscribe({
      next: value => {
        this.brands = value;
      }
    });
    this.fetch.getBrands();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  deleteGoal(id) {
    if (window.confirm('Are you sure you want to delete this goal?')) {
      this.requests.svc_delete(`/iapi/goal/${id}`, {}).pipe(takeUntil(this.destroy$)).subscribe({
        next: value => {
          this.snackBar.open('Goal successfully deleted', 'Close');
          this.fetch.getGoals();
        },
        error: err => {
          this.snackBar.open('An error occured deleting the goal', 'Close');
        }
      });
    }
  }

  deleteBrand(id) {
    if (window.confirm('Are you sure you want to delete this brand?')) {
      this.requests.svc_delete(`/iapi/brand/${id}`, {}).pipe(takeUntil(this.destroy$)).subscribe({
        next: () => {
          this.snackBar.open('Brand successfully deleted', 'Close');
          // this.fetch.getBrands();
          // We dont need to fetch all brands again, just delete the one that is clicked from
          // the array, backend will return the same on page reload
          for (let brand of this.brands) {
            if (brand.uri === id) {
              this.brands.splice(this.brands.indexOf(brand), 1);
            }
          }
        },
        error: () => {
          this.snackBar.open('An error occured deleting the brand', 'Close');
        }
      });
    }
  }
}
