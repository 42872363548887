import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {RequestsService} from './requests.service';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class FetchDataService {

  goals: Array<any>;
  goals$: Subject<Array<any>> = new Subject<Array<any>>();
  goal: any;
  goal$: Subject<any> = new Subject<any>();

  products: Array<any>;
  products$: Subject<Array<any>> = new Subject<Array<any>>();
  product: any;
  product$: Subject<any> = new Subject<any>();

  brandProducts: Array<any>;
  brandProducts$: Subject<Array<any>> = new Subject<Array<any>>();

  brands: Array<any>;
  brands$: Subject<Array<any>> = new Subject<Array<any>>();
  brand: any;
  brand$: Subject<any> = new Subject<any>();

  countries;
  countries$: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  industries;
  industries$: BehaviorSubject<any> = new BehaviorSubject<any>([]);


  constructor(private requests: RequestsService, private router: Router, private snackBar: MatSnackBar) {
    this.requests.svc_get('/iapi/countries', {}).subscribe({
      next: value => {
        this.countries = (value as any).countries;
        this.countries$.next(this.countries);
      }
    });
    this.requests.svc_get('/iapi/industries', {}).subscribe({
      next: value => {
        this.industries = (value as any).industries;
        this.industries$.next(this.industries);
      }
    });
  }

  getGoals() {
    this.requests.svc_get('/iapi/goals', {filters: JSON.stringify({type: ['GENERAL']}), limit: 500}).subscribe({
      next: value => {
        this.goals = (value as any).goals;
        this.goals$.next(this.goals);
      },
      error: err => {
        this.snackBar.open('An error occurred fetching goals.', 'Close', { duration: 2000 });
        this.router.navigateByUrl('/');
      }
    });
  }

  getGoal(id) {
    this.requests.svc_get(`/iapi/goal/${id}`, null).subscribe({
      next: value => {
        this.goal = (value as any).goal;
        this.goal$.next(this.goal);
      },
      error: err => {
        this.snackBar.open('An error occurred fetching goal.', 'Close', { duration: 2000 });
        this.router.navigateByUrl('/');
      }
    });
  }

  getProducts() {
    this.requests.svc_get('/iapi/products', null).subscribe({
      next: value => {
        this.products = (value as any).products;
        this.products$.next(this.products);
      },
      error: err => {
        this.snackBar.open('An error occurred fetching products.', 'Close', { duration: 2000 });
        this.router.navigateByUrl('/');
      }
    });
  }

  getBrands() {
    let data: any = {filters: JSON.stringify({status: ['NOT_OPEN']})};
    data = {...data, limit: 1000, fields: JSON.stringify(['id', 'name', 'uri']), order_by: 'name'};
    this.requests.svc_get('/iapi/brands', data).subscribe({
      next: value => {
        this.brands = (value as any).brands;
        this.brands$.next(this.brands);
      },
      error: err => {
        this.snackBar.open('An error occurred fetching products.', 'Close', { duration: 2000 });
        this.router.navigateByUrl('/');
      }
    });
  }

  getBrand(id) {
    this.requests.svc_get(`/iapi/brand/${id}`, {limit: 500}).subscribe({
      next: value => {
        this.brand = (value as any);
        this.brand$.next(this.brand);
      },
      error: err => {
        this.snackBar.open('An error occurred fetching brand.', 'Close', { duration: 2000 });
        this.router.navigateByUrl('/');
      }
    });
  }

  getBrandProducts(id) {
    this.requests.svc_get(`/iapi/brand/${id}`, {limit: 500}).subscribe({
      next: value => {
        this.brandProducts = (value as any).products;
        this.brandProducts$.next(this.brandProducts);
      },
      error: err => {
        this.snackBar.open('An error occurred fetching brand.', 'Close', { duration: 2000 });
        this.router.navigateByUrl('/');
      }
    });
  }
}
