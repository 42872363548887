<div class="login-form-wrapper" >
  <form [formGroup]="loginForm">
    <mat-form-field>
      <mat-label>Username</mat-label>
      <input matNativeControl formControlName="username">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Password</mat-label>
      <input matNativeControl type="password" formControlName="password">
    </mat-form-field>
    <button mat-button color="primary" (click)="submit()">Login</button>
  </form>
</div>
