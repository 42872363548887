import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {GoalFormComponent} from './forms/goal-form/goal-form.component';
import {LoginComponent} from './login/login.component';
import {AdminGuardGuard} from './guards/admin-guard.guard';
import {ProductFormComponent} from './forms/product-form/product-form.component';
import {BrandFormComponent} from './forms/brand-form/brand-form.component';
import {DashboardComponent} from './dashboard/dashboard.component';

const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: '', component: DashboardComponent, canActivate: [AdminGuardGuard]},
  {path: 'goal/add', component: GoalFormComponent, canActivate: [AdminGuardGuard]},
  {path: 'goal/edit/:id', component: GoalFormComponent, canActivate: [AdminGuardGuard]},
  {path: 'brand/add', component: BrandFormComponent, canActivate: [AdminGuardGuard]},
  {path: 'brand/edit/:id', component: BrandFormComponent, canActivate: [AdminGuardGuard]},
  {path: 'brand/:id/product/add', component: ProductFormComponent, canActivate: [AdminGuardGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
