import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {LoggeduserService} from '../services/loggeduser.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuardGuard implements CanActivate {

  constructor(private loggedUser: LoggeduserService,
              private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise(
      resolve => {
        if (document.cookie.indexOf('token') === -1) {
          this.router.navigate(['/login']);
        } else {
          this.loggedUser.checkUser()
            .then(
            (res) => {
              if ((res as any).role_flags === 2048) {
                this.loggedUser.token = (res as any).token;
                resolve(true);
              } else {
                resolve(false);
                this.router.navigate(['/login']);
              }
            })
            .catch(err => {
              this.router.navigate(['/login']);
            });
        }
      }
    );
  }
}
