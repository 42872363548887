<mat-spinner *ngIf="!brands"></mat-spinner>
<div *ngIf="brands" class="dashboard-wrapper">
<!--  <div class="goals-list">-->
<!--    <h1>Goals</h1>-->
<!--    <div *ngFor="let goal of goals" class="goal" [attr.data-name]="goal.title">-->
<!--      <div>{{goal.title}}</div>-->
<!--      <div>-->
<!--        <span class="pointer blue-hover no-outline" [attr.edit-goal]="goal.title" [routerLink]="'/goal/edit/' + goal.id">Edit</span>-->
<!--        /-->
<!--        <span (click)="deleteGoal(goal.id)" [attr.delete-goal]="goal.title" class="pointer blue-hover">Delete</span>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
  <div class="brands-list">
    <h1>Brands</h1>
    <div *ngFor="let brand of brands" class="goal" [attr.data-name]="brand.name">
      <div>{{brand.name}}</div>
      <div>
        <span class="pointer blue-hover no-outline" [attr.edit-brand]="brand.name" [routerLink]="'/brand/edit/' + brand.uri">Edit</span>
        /
        <span (click)="deleteBrand(brand.uri)" [attr.delete-brand]="brand.name" class="pointer blue-hover">Delete</span>
      </div>
    </div>
  </div>
</div>
