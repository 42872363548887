import { Component } from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LoggeduserService} from './services/loggeduser.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SettingsFormComponent} from "./forms/settings-form/settings-form.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'bemappsadmin';

  constructor(private router: Router, public loggedUser: LoggeduserService,
              public dialog: MatDialog,
              private httpClient: HttpClient, private snackBar: MatSnackBar) {}

  logout() {
    const header = {headers: new HttpHeaders({Authorization: this.loggedUser.getToken()})};
    this.loggedUser.token = null;
    this.httpClient.post('/user/logout', header).subscribe({
      next: value => {
        document.cookie = `token='';expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
        this.snackBar.open('You have successfully logged out.');
        this.router.navigateByUrl('/login');
      }
    });
  }

  openSettingsDialog() {
    const dialogRef = this.dialog.open(SettingsFormComponent, {
      width: '600px',
    });
  }
}
