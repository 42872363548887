import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {RequestsService} from '../../services/requests.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {FetchDataService} from '../../services/fetch-data.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import * as CustomEditor from '../../../ckeditor';
import {LoggeduserService} from "../../services/loggeduser.service";
import {ChangeEvent} from "@ckeditor/ckeditor5-angular";

@Component({
  selector: 'app-goal-form',
  templateUrl: './goal-form.component.html',
  styleUrls: ['./goal-form.component.scss']
})
export class GoalFormComponent implements OnInit, OnDestroy {
  @ViewChild('innovationDescription') innovationDescription: ElementRef;

  destroy$: Subject<boolean> = new Subject<boolean>();

  public Editor = CustomEditor;

  public descriptionLength: number = 0;

  countries: Array<any> = [];
  industries: Array<any> = [];

  selectedCountries = [];
  selectedIndustries = [];

  countriesSettings = {};
  industriesSettings = {};

  public editorConfig = {
    toolbar: ['bold', 'italic', '|', 'numberedlist', 'bulletedlist', '|', 'link', '|',
      'imageUpload', 'mediaEmbed', 'insertTable'],
    image: {
      upload: {
        types: ['png', 'jpeg'],
      }
    },
    simpleUpload: {
      uploadUrl: '/iapi/ckeditor/upload',
      headers: {
        Authorization: this.loggedUser.token,
      }
    },
    mediaEmbed: {
      previewsInData: true,
    }
  };

  goal = {};
  goalEditId;

  goalForm = this.fb.group({
    title: [null, Validators.required],
    image: [null],
    description: [null],
    industries: [null],
    countries: [null],
  });

  constructor(private fb: FormBuilder,
              private requests: RequestsService,
              private snackBar: MatSnackBar,
              private route: ActivatedRoute,
              private fetch: FetchDataService,
              private loggedUser: LoggeduserService,
              private router: Router) {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe({
      next: param => {
        if (param && param.get('id')) {
          this.goalEditId = param.get('id');
          this.goal = null;
          this.fetch.getGoal(this.goalEditId);
          this.fetch.goal$.pipe(takeUntil(this.destroy$)).subscribe({
            next: goal => {
              this.goal = goal;
              this.patchDefaults(this.goal);
            }
          });
        }
      }
    });

    this.fetch.countries$.pipe(takeUntil(this.destroy$)).subscribe({
      next: value => {
        if ((value as any).length > 0) {
          this.countries = (value as any);
        }
      }
    });

    this.fetch.industries$.pipe(takeUntil(this.destroy$)).subscribe({
      next: value => {
        if ((value as any).length > 0) {
          this.industries = (value as any);
        }
      }
    });
  }

  ngOnInit(): void {
    this.countriesSettings = {
      singleSelection: false,
      text: 'Select countries',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      labelKey: 'name',
      enableCheckAll: false,
    };

    this.industriesSettings = {
      singleSelection: false,
      text: 'Select categories',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      labelKey: 'name',
      enableCheckAll: false,
    };
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  patchDefaults(goal) {
    this.goalEditId = goal.id;
    this.goalForm.patchValue({
      title: goal.title,
      description: goal.description,
      industries: goal.industries_names,
      countries: goal.countries_names
    });
    this.selectedCountries = goal.countries_names;
    this.selectedIndustries = goal.industries_names;
  }

  onReady(event) {

  }

  onChange({editor, event}: ChangeEvent) {
    if (editor) {
      this.goalForm.patchValue({
        description: editor.getData()
      });
      // this.descriptionLength = length;
      setTimeout(() => {
        this.descriptionLength = this.innovationDescription.nativeElement.textContent.length;
      });
    }
  }

  submit(): void {
    this.goalForm.patchValue({
      countries: this.selectedCountries.map(country => country.id),
      industries: this.selectedIndustries.map(industry => industry.id),
    });

    if (this.goalForm.invalid) {
      this.snackBar.open('Please enter valid data', 'Close', { duration: 2000 });
    } else {
      if (this.goalEditId) {
        this.requests.svc_put(`/iapi/goal/${this.goalEditId}`, this.goalForm.value).pipe(takeUntil(this.destroy$)).subscribe({
          next: value => {
            this.snackBar.open('Goal edited', 'Close', { duration: 2000 });
            this.router.navigateByUrl('/');
          },
          error: err => {
            this.snackBar.open('Error editing a goal', 'Close', { duration: 2000 });
          }
        });
      } else {
        this.requests.svc_put('/iapi/goals', this.goalForm.value).pipe(takeUntil(this.destroy$)).subscribe({
          next: value => {
            this.router.navigateByUrl('/');
            this.snackBar.open('Goal added', 'Close', { duration: 2000 });
          },
          error: err => {
            this.snackBar.open('Error adding a goal', 'Close', { duration: 2000 });
          }
        });
      }
    }
  }
}
