<mat-spinner *ngIf="!goal"></mat-spinner>
<div *ngIf="goal" class="goal-form-wrapper">
  <form>
    <div [formGroup]="goalForm">
      <mat-form-field style="width: 100%;">
        <mat-label>Title</mat-label>
        <input id="goal_form_title_input" matNativeControl formControlName="title">
      </mat-form-field>
      <!--    <mat-form-field>-->
      <!--      <mat-label>Description</mat-label>-->
      <!--      <input id="goal_form_description_input" matNativeControl formControlName="description">-->
      <!--    </mat-form-field>-->
    </div>
    <angular2-multiselect [data]="countries" [(ngModel)]="selectedCountries"
                          [settings]="countriesSettings" [ngModelOptions]="{standalone: true}"></angular2-multiselect>
    <angular2-multiselect [data]="industries" [(ngModel)]="selectedIndustries"
                          [settings]="industriesSettings" [ngModelOptions]="{standalone: true}"></angular2-multiselect>
    <ckeditor (ready)="onReady($event)" [config]="editorConfig" [editor]="Editor"
              [data]="goalForm.get('description').value"
              (change)="onChange($event)"></ckeditor>
    <button mat-flat-button color="primary" id="goal_form_submit_button" (click)="submit()">Submit</button>
  </form>
</div>
<div [hidden]="true" [innerHTML]="goalForm.get('description').value | sanitizeHtml" #innovationDescription></div>
