import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, AsyncValidatorFn, FormBuilder, ValidationErrors, Validators} from '@angular/forms';
import {RequestsService} from '../../services/requests.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {FetchDataService} from '../../services/fetch-data.service';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {ImageUploadService} from '../../services/image-upload.service';
import {DropdownSettings} from 'angular2-multiselect-dropdown/lib/multiselect.interface';
import {ProductFormComponent} from '../product-form/product-form.component';
import {MatDialog} from '@angular/material/dialog';
import {Observable, of, Subject} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, first, map, switchMap, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-brand-form',
  templateUrl: './brand-form.component.html',
  styleUrls: ['./brand-form.component.scss']
})
export class BrandFormComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();

  imageChangedEvent: any = '';
  coverImageChangedEvent: any = '';
  croppedImage: any = '';
  badImageFile: boolean = false;
  imageFileName: string = '';

  brand: any = {};
  products = [];
  brandEditId;

  countries: Array<any> = [];
  industries: Array<any> = [];

  brandForm = this.fb.group({
    name: [null, Validators.required],
    logo_image: [null, Validators.required],
    uri: [null, Validators.required, this.userUriValidator()],
    cover_image: [null],
    industries: [null],
    countries: [null],
    url: this.fb.group({
      name: [null,],
      url: [null,],
    }),
    social_links: this.fb.group({
      instagram: [null],
      facebook: [null],
      linkedin: [null],
      twitter: [null],
      youtube: [null],
      discord: [null],
      googlePlay: [null],
      appleStore: [null]
    })
  });
  public tags: Array<any> = [];
  private selectedTags: Array<string> = [];

  constructor(private fb: FormBuilder, private requests: RequestsService, private snackBar: MatSnackBar,
              private route: ActivatedRoute, private fetch: FetchDataService, private router: Router,
              private imageUpload: ImageUploadService, public dialog: MatDialog) {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe({
      next: param => {
        if (param && param.get('id')) {
          this.brandEditId = param.get('id');
          this.brand = null;
          this.fetch.getBrand(this.brandEditId);
          this.fetch.brand$.pipe(takeUntil(this.destroy$)).subscribe({
            next: brand => {
              this.brand = brand;
              this.products = brand.products;
              this.patchDefaults(this.brand);
            }
          });
          this.fetch.brandProducts$.pipe(takeUntil(this.destroy$)).subscribe({
            next: products => {
              this.products = products;
              this.brandForm.patchValue({
                products
              });
            }
          });
        }
      }
    });
    this.fetch.countries$.pipe(takeUntil(this.destroy$)).subscribe({
      next: value => {
        if ((value as any).length > 0) {
          this.countries = (value as any);
        }
      }
    });
    this.fetch.industries$.pipe(takeUntil(this.destroy$)).subscribe({
      next: value => {
        if ((value as any).length > 0) {
          this.industries = (value as any);
        }
      }
    });
  }

  countriesSettings = {};
  industriesSettings = {};

  selectedCountries = [];
  selectedIndustries = [];

  ngOnInit() {
    this.countriesSettings = {
      singleSelection: false,
      text: 'Select countries',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      labelKey: 'name',
      enableCheckAll: false,
    };

    this.industriesSettings = {
      singleSelection: false,
      text: 'Select categories',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      labelKey: 'name',
      enableCheckAll: false,
    };
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  fileChangeEvent(event: any, type) {
    this.coverImageChangedEvent = '';
    this.imageChangedEvent = '';
    if (type === 'cover') {
      this.coverImageChangedEvent = event;
    } else {
      this.imageChangedEvent = event;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded(type) {
    this.badImageFile = false;
    if (type === 'cover') {
      this.imageFileName = this.coverImageChangedEvent.target.files[0].name;

    } else {
      this.imageFileName = this.imageChangedEvent.target.files[0].name;
    }
  }

  onTagsChange() {
    this.selectedTags = [];
    for (let tag of this.tags) {
      this.selectedTags.push(tag.name);
    }
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    this.badImageFile = true;
    this.imageChangedEvent = '';
    this.coverImageChangedEvent = '';
    this.croppedImage = '';
  }

  uploadImage(type) {
    if (this.badImageFile === false && this.croppedImage !== '') {
      this.imageUpload.uploadPhoto('brand', this.croppedImage, this.imageFileName).pipe(takeUntil(this.destroy$)).subscribe({
        next: value => {
          this.snackBar.open('Image successfully uploaded.', 'Close', {duration: 2000});
          if (type === 'cover') {
            this.brandForm.patchValue({
              cover_image: (value as any).image_name,
            });
          } else {
            this.brandForm.patchValue({
              logo_image: (value as any).image_name,
            });
          }

          this.imageChangedEvent = '';
          this.coverImageChangedEvent = '';
          this.croppedImage = '';
          this.badImageFile = false;
          this.imageFileName = '';
        },
        error: err => {
          this.snackBar.open('Image upload failed.', 'Close', {duration: 2000});
          return null;
        }
      });
    } else {
      this.snackBar.open('Please choose a valid image', 'Close', {duration: 2000});
    }
  }

  userUriValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      return control.valueChanges
        .pipe(
          debounceTime(250),
          distinctUntilChanged(),
          // tap(() => this.asyncErrorCache.brandUriExists = false),
          switchMap(value => this.checkUserURI(control.value)),
          map(res => null),
          catchError(err => {
            // this.asyncErrorCache.brandUriExists = true;
            switch (err.error?.id_error) {
              case 1003:
                return of({brandUriExists: true});
              case 1006:
                return of({userUriExists: true});
              default:
                return of({genericAsyncError: true});
            }
          }),
        )
        .pipe(first());
    };
  }

  checkUserURI(uri: string): Observable<any> {
    // Sending username for URI checking in case of unverified users re-registering with the same URI
    // Will throw an error for everyone else, but for the user with the same username who has registered using the same URI prior but
    // is still not verified
    let data: any = {uri};
    // if (this.registerEmail.value && this.registerEmail.valid) {
    //   data = {...data, username: this.registerEmail.value};
    // }

    return this.requests.svc_get('/iapi/users/check', data);
  }

  patchDefaults(brand) {
    this.brandEditId = brand.uri;
    this.brandForm.patchValue({
      name: brand.name,
      uri: brand.uri,
      industries: brand.industries_names,
      countries: brand.countries_names,
      logo_image: brand.images?.logo ? brand.images.logo : null,
      cover_image: brand.images?.cover ? brand.images.cover : null,
    });
    this.brandForm.get('url').patchValue({
      name: brand.url?.name,
      url: brand.url?.url,
    });
    this.brandForm.get('social_links').patchValue({
      instagram: brand.social_links?.instagram,
      facebook: brand.social_links?.facebook,
      linkedin: brand.social_links?.linkedin,
      twitter: brand.social_links?.twitter,
      youtube: brand.social_links?.youtube,
      discord: brand.social_links?.discord,
      googlePlay: brand.social_links?.googlePlay,
      appleStore: brand.social_links?.appleStore
    })

    this.tags = brand.tags;
    if (brand.tags) {
      for (let tag of brand.tags) {
        this.selectedTags.push(tag.name);
      }
    }

    this.selectedCountries = brand.countries_names;
    this.selectedIndustries = brand.industries_names;
  }

  submit(): void {
    this.brandForm.patchValue({
      // countries: this.selectedCountries.map(country => country.id),
      // industries: this.selectedIndustries.map(industry => industry.id),
    });

    let brandSubmitForm = this.brandForm.value;
    if (this.brandEditId && this.brand.uri !== this.brandForm.value.uri) {
      brandSubmitForm = {...brandSubmitForm, new_uri: this.brandForm.value.uri};
    }

    if (this.selectedTags) {
      brandSubmitForm = {...brandSubmitForm, tags: this.selectedTags};
    }

    if (this.brandForm.invalid) {
      if (this.brandForm.get('logo_image').errors?.required) {
        this.snackBar.open('Please choose and upload brand logo', 'Close', {duration: 2000});
      } else {
        this.snackBar.open('Please enter valid data', 'Close', {duration: 2000});
      }
    } else {
      if (this.brandEditId) {
        this.requests.svc_put(`/iapi/brand/${this.brandEditId}`, brandSubmitForm).pipe(takeUntil(this.destroy$)).subscribe({
          next: value => {
            this.snackBar.open('Brand edited', 'Close', {duration: 2000});
            this.router.navigateByUrl('/');
          },
          error: err => {
            this.snackBar.open('Error editing a brand', 'Close', {duration: 2000});
          }
        });
      } else {
        this.requests.svc_put('/iapi/brands', brandSubmitForm).pipe(takeUntil(this.destroy$)).subscribe({
          next: value => {
            this.router.navigateByUrl('/');
            this.snackBar.open('Brand added', 'Close', {duration: 2000});
          },
          error: err => {
            this.snackBar.open('Error adding a brand', 'Close', {duration: 2000});
          }
        });
      }
    }
  }

  deleteProduct(id) {
    if (window.confirm('Are you sure you want to delete this product?')) {
      this.requests.svc_delete(`/iapi/product/${id}`, {}).pipe(takeUntil(this.destroy$)).subscribe({
        next: value => {
          this.snackBar.open('Product successfully deleted', 'Close');
          this.fetch.getBrandProducts(this.brandEditId);
        },
        error: err => {
          this.snackBar.open('An error occured deleting the product', 'Close');
        }
      });
    }
  }

  onAddProduct() {
    const dialogRef = this.dialog.open(ProductFormComponent, {
      width: '600px',
      data: {brandId: this.brand.id}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
      if (result) {
        this.fetch.getBrandProducts(this.brandEditId);
      }
    });
  }

  editProduct(product) {
    const dialogRef = this.dialog.open(ProductFormComponent, {
      width: '600px',
      data: {product, brandId: this.brand.id}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
      if (result) {
        this.fetch.getBrand(this.brandEditId);
      }
    });
  }

}
