<mat-toolbar>
  <span class="noselect blue-hover pointer no-outline" [routerLink]="'/'">BEM admin</span>
  <span class="navigation-link noselect" id="navigation_home_button" [routerLink]="'/'">Home</span>
<!--  <span class="navigation-link noselect" id="navigation_add_goal_button" [routerLink]="'/goal/add'">Add goal</span>-->
  <span class="navigation-link noselect" id="navigation_add_brand_button" [routerLink]="'/brand/add'">Add brand</span>
  <span *ngIf="loggedUser.token" class="navigation-link noselect" id="navigation_settings_button" (click)="openSettingsDialog()">Settings</span>
  <span *ngIf="!loggedUser.token" class="navigation-link noselect" id="navigation_settings_button">Settings</span>
  <span class="fill-space"></span>
  <span class="navigation-link noselect" *ngIf="loggedUser.token" (click)="logout()">Logout</span>
</mat-toolbar>
<router-outlet></router-outlet>
