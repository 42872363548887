import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {RequestsService} from "../../services/requests.service";
import {stringify} from "@angular/compiler/src/util";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-settings-form',
  templateUrl: './settings-form.component.html',
  styleUrls: ['./settings-form.component.scss']
})
export class SettingsFormComponent implements OnInit {

  isOpen: boolean = false;

  constructor(public dialogRef: MatDialogRef<SettingsFormComponent>,
              private requests: RequestsService, private snackBar: MatSnackBar,
              @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.requests.svc_get('/iapi/admin/settings', {option: 'open_pages'}).subscribe({
      next: (resp: {open_pages: string}) => {
        this.isOpen = resp.open_pages !== 'false';
      },
      error: err => {
        this.snackBar.open('An error occurred.', 'Close', { duration: 2000 });
      }
    });
  }

  submit(): void {
    const data = {
      option: 'open_pages',
      value: this.isOpen.toString(),
    };

    this.requests.svc_put('/iapi/admin/settings', data).subscribe({
      next: resp => {
        this.snackBar.open('Successfully changed options.', 'Close', { duration: 2000 });
        this.dialogRef.close();
      },
      error: err => {
        this.snackBar.open('An error occurred.', 'Close', { duration: 2000 });
      }
    });
  }

}
