<div class="product-form-wrapper" >
  <form [formGroup]="productForm">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input id="product_form_name_input" matNativeControl formControlName="name">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Website URL</mat-label>
      <input id="product_form_url_input" matNativeControl formControlName="url">
    </mat-form-field>
    <input #imageInput id="product_form_image_input" hidden="true" type="file" onclick="this.value=null" (change)="fileChangeEvent($event)" accept=".jpg,.png"/>
    <div *ngIf="imageChangedEvent !== ''">
      <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [style.maxHeight.px]="200"
        format="png"
        [imageQuality]="100"
        [cropperMinWidth]="900"
        [cropperMinHeight]="450"
        [aspectRatio]="2"
        [maintainAspectRatio]="true"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"
      ></image-cropper>
    </div>
    <button mat-flat-button color="primary" id="product_form_choose_image_button" (click)="imageInput.click()">Choose image</button>
    <button mat-flat-button color="primary" id="product_form_upload_image_button" *ngIf="imageChangedEvent !== ''" (click)="uploadImage()">Upload image</button>
    <button mat-flat-button color="primary" id="product_form_submit" (click)="submit()">Submit</button>
  </form>
</div>
