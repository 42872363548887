import { Injectable } from '@angular/core';
import {RequestsService} from './requests.service';

@Injectable({
  providedIn: 'root'
})
export class ImageUploadService {

  constructor(private requests: RequestsService) { }

  uploadPhoto(type, image, imageName) {
    const data = {
      image_type: type,
      image_data: image,
      original_name: imageName
    };
    return this.requests.svc_post('/iapi/images/upload', data );
  }
}
