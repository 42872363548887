<div class="settings-form-wrapper">
  <mat-form-field>
    <mat-label>Open pages setting</mat-label>
    <mat-select [(value)]="isOpen">
      <mat-option [value]="true">Open</mat-option>
      <mat-option [value]="false">Closed</mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-flat-button color="primary" id="product_form_submit" (click)="submit()">Submit</button>
</div>
