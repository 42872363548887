import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {RequestsService} from '../../services/requests.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {ImageUploadService} from '../../services/image-upload.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss']
})
export class ProductFormComponent implements OnInit {

  imageChangedEvent: any = '';
  croppedImage: any = '';
  badImageFile: boolean = false;
  imageFileName: string = '';

  productEditId = null;
  product = null;

  productForm = this.fb.group({
    name: [null, Validators.required],
    image: [null, Validators.required],
    url: [null],
  });

  constructor(private fb: FormBuilder, private requests: RequestsService,
              private snackBar: MatSnackBar, private imageUpload: ImageUploadService,
              public dialogRef: MatDialogRef<ProductFormComponent>,
              @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    if (this.data && this.data.product && this.data.brandId) {
      this.product = this.data.product;
      this.productEditId = this.product.id;
      this.patchDefaults(this.product);
    }
  }

  patchDefaults(product) {
    this.productForm.patchValue({
      name: product.name,
      url: product.url?.url,
      image: product.image ? product.image : null
    });
  }

  fileChangeEvent(event: any) {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    this.badImageFile = false;
    this.imageFileName = this.imageChangedEvent.target.files[0].name;
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    this.badImageFile = true;
    this.imageChangedEvent = '';
    this.croppedImage = '';
  }

  uploadImage() {
    if (this.badImageFile === false && this.croppedImage !== '') {
      this.imageUpload.uploadPhoto('product', this.croppedImage, this.imageFileName).subscribe({
        next: value => {
          this.snackBar.open('Image successfully uploaded.', 'Close', { duration: 2000 });
          this.productForm.patchValue({
            image: (value as any).image_name,
          });
        },
        error: err => {
          this.snackBar.open('Image upload failed.', 'Close', { duration: 2000 });
          return null;
        }
      });
    } else {
      this.snackBar.open('Please choose a valid image', 'Close', { duration: 2000 });
    }
  }

  submit(): void {

    const productFormSubmit = {
      id_brand: this.data.brandId,
      name: this.productForm.value.name,
      url: {
        url: this.productForm.value.url,
        name: this.productForm.value.name,
      },
      image: this.productForm.value.image
    };

    if (this.productForm.invalid) {
      this.snackBar.open('Please enter valid data', 'Close', { duration: 2000 });
    } else {
      if (this.productEditId) {
        this.requests.svc_put(`/iapi/product/${this.productEditId}`, productFormSubmit).subscribe({
          next: value => {
            this.snackBar.open('Product added', 'Close', { duration: 2000 });
            this.dialogRef.close(true);
          },
          error: err => {
            this.snackBar.open('Error adding a product', 'Close', { duration: 2000 });
            this.dialogRef.close(false);
          }
        });
      } else {
        this.requests.svc_put('/iapi/products', productFormSubmit).subscribe({
          next: value => {
            this.snackBar.open('Product added', 'Close', { duration: 2000 });
            this.dialogRef.close(true);
          },
          error: err => {
            this.snackBar.open('Error adding a product', 'Close', { duration: 2000 });
            this.dialogRef.close(false);
          }
        });
      }
    }
  }

}
